.text-u {
    font-size: 15px;
    margin-top: 20px;
    max-width: 500px; /* Size of the paragraph text */
  }

.btn-color {
  background-color: blueviolet;
}

.btn-color:hover {
  background-color: rgb(106, 34, 173);
}

/* Ensure the h2 is bold */
.underline-purple {
  font-weight: bold; /* Makes the text bold */
  position: relative; /* Needed for the pseudo-element */
  display: inline-block; /* Keeps the underline under the text only */
  color: #ffffff; /* White text as you specified */
  padding-bottom: 10px; /* Increased space for the squiggly effect */
}

/* Add the cool squiggly purple underline */
.underline-purple::after {
  content: ''; /* Empty content for the underline */
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%; /* Full width of the text */
  height: 8px; /* Increased height for visibility of the squiggle */
  /* Use an SVG background for a squiggly effect */
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 4'%3E%3Cpath fill='none' stroke='%238a2be2' stroke-width='2' d='M0 2 Q 5 4 10 2 T 20 2'/%3E%3C/svg%3E");
  background-repeat: repeat-x; /* Repeat the wave horizontally */
  background-size: 20px 8px; /* Size of one wave segment */
  transform: scaleX(0); /* Start with no width */
  transform-origin: bottom left; /* Animation starts from the left */
  transition: transform 0.3s ease-in-out; /* Smooth animation */
}

/* Animate the underline on hover or always visible */
.underline-purple:hover::after,
.underline-purple::after { /* Remove :hover if you want it always visible */
  transform: scaleX(1); /* Expand to full width */
}


.btn-color {
  background-color: #8a2be2; /* Purple */
  color: #ffffff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: transform 0.2s, background-color 0.2s;
}

.btn-color:hover {
  transform: scale(1.05); /* Slight zoom effect */
  background-color: #6a1bb2; /* Darker purple */
}

.optional {
  font-size: 12px;
  color: #888;
}

.trust-text {
  font-size: 14px;
  color: #666;
  margin-top: 10px;
}