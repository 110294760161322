/* SuccessPage.css */

.success-section {
    padding: 220px;
  }
  
  .success-container {
    max-width: 600px;
    margin: 0 auto;
    text-align: center;
  }
  
  .success-container h2 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .text-u {
    font-size: 16px;
    margin-top: 20px;
  }
  
  /* Checkmark styles */
  .checkmark-circle {
    width: 100px;
    height: 100px;
    position: relative;
    margin: 0 auto;
  }
  
  .checkmark__circle {
    stroke: #4CAF50; /* Green color */
    stroke-width: 2;
    stroke-miterlimit: 10;
    animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
  }
  
  .checkmark {
    width: 100px;
    height: 100px;
  }
  
  .checkmark__check {
    stroke: #4CAF50;
    stroke-width: 2;
    stroke-miterlimit: 10;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
  }
  
  @keyframes stroke {
    100% {
      stroke-dashoffset: 0;
    }
  }